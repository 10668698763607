import {React,useState} from 'react'
import {medicalExaminationArea,MedicalClinic} from "../../fakeDB"
import img1 from "../../images/MockupiHospital.png"
import "./GetNumberClinical.scss"
import { AiOutlineDown } from "react-icons/ai";
import { convertNumber } from '../../utils/utils'
import Swal from 'sweetalert2'


function GetNumberClinical() {

  let arrCheckbox = []
  const [idItem, setIdItem] = useState(0)
  const [activeOption,setActiveOption] = useState(false)
  const [idPhongKham,setIdPhongKham] = useState()
  const [render,setRender] = useState(0)

  for(let i = 0 ; i < MedicalClinic.length ; i++){
    if(MedicalClinic[i].idArea === medicalExaminationArea[idItem].idArea){
      arrCheckbox.push(MedicalClinic[i])
    }
  }

  let stt = idPhongKham === undefined ?   0 :  arrCheckbox[idPhongKham].stt;
  

  const HandleCheckbox = (id) =>{
    setIdPhongKham(id)
  }


  const handleGetNumber = ()=>{

    if(idPhongKham !== undefined){
      Swal.fire({
        title: 'Lấy số thành công',
        text: ` ${arrCheckbox[idPhongKham].room} : STT ${convertNumber(arrCheckbox[idPhongKham].stt + 1,4)}`,
        icon: 'success',
        confirmButtonText: 'Đóng'
      })

      arrCheckbox[idPhongKham].stt = arrCheckbox[idPhongKham].stt + 1 
    }
    else{
      Swal.fire({
        title: 'Vui lòng chọn dịch vụ',
        text: "",
        icon: 'error',
        confirmButtonText: 'Đóng'
      })
    }
    setRender(render +1)
  }

  return (
    <div className='getNumber__clinical'>
      <div className="getNumber ">
          <h5 className='getNumber__title'>
            KHU VỰC LẤY SỐ KHÁM LÂM SÀN
          </h5>
          <div className='getNumber__container'>
              <div className='getNumber__container__img'>
                {/* <img src={img2} alt="ảnh 1"/> */}
                <img className='getNumber__logo__img' src={img1} alt="ảnh 2"/>
              </div>
              <div className='getNumber__content' style={{marginLeft: "20px"}}>
                  <div style={{margin : "auto 0"}}>
                    <div className="checkbox t_a-c">
                      {arrCheckbox 
                        ? arrCheckbox.map((value,index) =>(
                          <div className="checkbox-group" key={index} onClick={()=>HandleCheckbox(index)}>
                            <input type="radio" name="getnumber" id={value.room} value={value.room}/>
                            <label htmlFor={value.room}>{value.room}</label>
                        </div>
                        ))
                        :'<h5>Không có dữ liệu</h1>'
                      }
                  </div>  
                    <div className='submit__number'>
                      <p>
                        {convertNumber(stt,4)}
                      </p>
                      <div className='btn__getnumber' onClick={handleGetNumber}>Lấy số</div>
                    </div>
                  </div>
                  <div className='dropdown-area'>
                      <div className={activeOption ? "dropdown-area-input box-shadow" :"dropdown-area-input"} 
                        onClick={() =>{
                          setActiveOption(true)
                          setIdPhongKham(0)
                          }}>
                        {medicalExaminationArea[idItem].name}
                        <AiOutlineDown/>
                      </div>
                      <ul className={activeOption ? "dropdown_arae-active" :""}>
                        {
                          medicalExaminationArea.map(value =>(
                            <li 
                              key={value.id} 
                              onClick={() =>{
                                setIdItem(value.id)
                                setActiveOption(false)
                                setIdPhongKham()
                                }}>
                              {value.name}
                            </li>
                          ))
                        }
                      </ul>
                  </div>
              </div>
              {/* <div className='getNumber__inputID'>
                    <label>
                      Id bệnh nhân
                    </label>
                    <input type="text"/>
              </div> */}
          </div>
    </div>
    </div>
  )
}

export default GetNumberClinical