import React from 'react'
import "./rating.scss"

import {AiFillStar} from "react-icons/ai"
import ButtonBack from "../../components/buttonback/ButtonBack";

function Rating() {
  return (
    <div className='rating__page'>
        <p>Vui lòng đánh giá dịch vụ</p>
        <div>
            <AiFillStar className='rating-active'/>
            <AiFillStar className='rating-active'/>
            <AiFillStar className='rating-active'/>
            <AiFillStar className='rating-active'/>
            <AiFillStar/>
        </div>
        <ButtonBack slug="/"/>
    </div>
  )
}

export default Rating