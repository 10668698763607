import { useState } from "react";
import { React, useEffect } from "react";
import { url } from "../../api/api";
import ButtonBack from "../../components/buttonback/ButtonBack";
import { convertDateTime, convertNumber } from "../../utils/utils";

export default function ScreenPM() {
  const [data, setData] = useState([]);
  const [state, set] = useState(0);

  useEffect(() => {
    async function fetchListVideo() {
      const requesURL = `${url}/manhinh/phongmo`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();
      setData(responseJson);
    }
    fetchListVideo();
    reRender();
  }, [state]);

  function reRender() {
    setTimeout(() => {
      set((pre) => pre + 1);
    }, 3000);
  }
  return (
    <div className="">
      <div className="screen__title">
        <h2>Thông tin bệnh nhân phòng mổ </h2>
      </div>

      <div className="formpay__table">
        <div className="searchMedia">
          <div className="scroll-table">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên bệnh nhân</th>
                  <th>Năm sinh</th>
                  <th>Thời gian mổ xong</th>
                  <th style={{ width: "40%" }}>Ghi chú</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((value, index) => (
                    <tr key={index}>
                      <td>{convertNumber(index + 1, 2)}</td>
                      <td>{value.TenBenhNhan}</td>
                      <td>{value.NamSinh}</td>
                      <td>{convertDateTime(value.NgayMo)}</td>
                      <td>{value.ghichu ? value.ghichu : "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td>Chưa có bệnh nhân nào</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ButtonBack slug="/manhinh" />
    </div>
  );
}
