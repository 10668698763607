import {useState,useEffect} from "react";
import "./TraCuuBenhAn.scss";
import { convertDateTime, convertNumber } from "../../utils/utils";
import { url } from "../../api/api";
import ButtonBack from "../../components/buttonback/ButtonBack";

function TraCuuBenhAn() {
 
  const [idBenhNhan,setIdBenhNhan] = useState (0)
  const [benhAn,setBanhAn] = useState ([])
  const [thongTinBenhNhan,setThongTInBenhNhan] = useState({})

  const handleChange = (event) => {
    setIdBenhNhan(event.target.value);
  }

  useEffect(() => {
    async function fetchData() {
      const requesURL = `${url}/patient/${idBenhNhan}`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();

      setBanhAn(responseJson)
      
    }
      fetchData();
  }, [idBenhNhan]);

  useEffect(() => {
    async function fetchData() {
      const requesURL = `${url}/benhnhan/${idBenhNhan}`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();

      setThongTInBenhNhan(responseJson)
      
    }
      fetchData();
  }, [idBenhNhan]);

let ten
let namsinh
if(thongTinBenhNhan.length >0){
   ten = thongTinBenhNhan[0].Ten
   namsinh = thongTinBenhNhan[0].NamSinh
}
else{
  console.log(false)
}
  return (
    <div className="searchMedia">
      <h1 className="">Tra cứu thông tin bệnh án</h1>
      <div className="searchMedia-input" style={{display:"flex"}}>
        <p>Tìm kiếm</p>
        <input onChange={handleChange} type="number" placeholder="Nhập tên bệnh nhân | mã bệnh nhân"/>
      </div>
      <div className="scroll-table">
      <table>
        <thead>
          <tr>
            <th>STT</th>

            <th>Tên Bệnh nhân</th>

            <th>Ngày sinh</th>


            <th>Chẩn đoán</th>

            <th>Thời gian khám</th>
          </tr>
        </thead>

        <tbody>
          {
            benhAn.length > 0
              ?benhAn.map((value, index) => (
                <tr key={index}>
                  <td>{convertNumber(index + 1,2)}</td>
                  <td>{ten}</td>
                  <td>{ convertDateTime(namsinh)}</td>
                  <td>{value.ChanDoan ? value.ChanDoan : "-" }</td>
                  <td>{convertDateTime(value.NgayKham)}</td>
                </tr>
              ))
            : <tr>
              <td colSpan={5}>Không có dữ liệu</td>
            </tr>
          }
        </tbody>
      </table>
      </div>
      <ButtonBack slug="/"></ButtonBack>
    </div>
  );
}

export default TraCuuBenhAn;



