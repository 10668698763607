import React from "react";

function ScreenPhongKham() {
  return (
    <div className="screen screen__thuphi">
      <div className="screen__title">
        <h2>Danh sách bệnh nhân nhận thuốc</h2>
      </div>
      <div className="formpay__table">
        <div className="searchMedia">
          <div className="scroll-table">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>STT</th>
                  <th style={{ width: "40%" }}>Tên bệnh nhân</th>
                  <th>Năm sinh</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>01</td>
                  <td>Vy Thị Lan Anh</td>
                  <td>1998</td>
                </tr>
                <tr>
                  <td>02</td>
                  <td>Đinh Phan Chi Tâm</td>
                  <td>1980</td>
                </tr>
                <tr>
                  <td>03</td>
                  <td>Hà Thu Thủy</td>
                  <td>1986</td>
                </tr>
                <tr>
                  <td>04</td>
                  <td>Đăng Vinh</td>
                  <td>2000</td>
                </tr>
                 <tr>
                  <td>05</td>
                  <td>Chu Vĩnh Ân</td>
                  <td>1997</td>
                </tr>
                <tr>
                  <td>06</td>
                  <td>Hồ Minh Hiếu</td>
                  <td>1997</td>
                </tr>
                <tr>
                  <td>07</td>
                  <td>Vũ Phương Thảo</td>
                  <td>1990</td>
                </tr>
                <tr>
                  <td>08</td>
                  <td>Lọi  Tuấn Đức</td>
                  <td>2000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScreenPhongKham;
