import React from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./buttonback.scss";

export default function ButtonBack({slug}) {
  const navigate = useNavigate()

  return (
    <div className="backbutton" onClick={() => navigate(slug)}>
      <div>
        <MdOutlineKeyboardBackspace />
      </div>
    </div>
  );
}
