import React from "react";
import { useNavigate } from "react-router-dom";
import "./listscreen.scss";

function ListScreen() {
  const navigate = useNavigate();
  const listScreen = [
    {
      name: "Phòng mổ",
      slug: "phong-mo",
    },

    {
      name: "Hồi sức tích cực",
      slug: "hoi-suc-tich-cuc",
    },
    {
      name: "Phòng sanh",
      slug: "phong-sanh",
    },

    {
      name: "Tiếp nhận dịch vụ",
      slug: "dich-vu",
    },
    {
      name: "Tiếp nhận ưu tiên",
      slug: "uu-tien",
    },
    {
      name: "Tiếp nhận đặt hẹn",
      slug: "dat-hen",
    },
    {
        name: "Cấp cứu",
        slug: "cap-cuu",
      },
   
    {
      name: "Chuẩn đoán hình ảnh",
      slug: "chuan-doan",
    },
    {
      name: "Thu phí",
      slug: "thu-phi",
    },

    // {
    //   name: "Bác sĩ",
    //   slug: "bac-si",
    // },
    // {
    //   name: "Quầy thuốc",
    //   slug: "lay-thuoc",
    // },
    // {
    //     name: "Khám lâm sàn",
    //     slug: "lam-san",
    //   },
  ];

  const handleRouter = (slug) => {
    navigate(slug);
  };
  return (
    <div className="ListScreen">
      <div className="ListScreen__title">Danh sách màn hình bệnh viện</div>

      <div className="ListScreen__screen">
        <ul>
          {listScreen.map((value, index) => (
            <li key={index} onClick={() => handleRouter(value.slug)}>
              {value.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ListScreen;
