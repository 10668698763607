import { React, useEffect, useState } from "react";
import { url } from "../../api/api";
import ButtonBack from "../../components/buttonback/ButtonBack";
import { convertNumber } from "../../utils/utils";

export default function ScreenHSTC() {
  const [data, setData] = useState([]);
  const [render, setRender] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const requesURL = `${url}/manhinh/hoisuc`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();
      setData(responseJson);
    };
    fetchData();
    Rerender();
  }, [render]);

  const Rerender = () => {
    setTimeout(() => {
      setRender((pre) => pre + 1);
    }, 3000);
  };

  return (
    <div className="">
      <div className="screen__title">
        <h2>Thông tin bệnh nhân khoa hồi sức tích cực </h2>
      </div>
      <div className="formpay__table">
        <div className="searchMedia">
          <div className="scroll-table">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên bệnh nhân</th>
                  <th>Năm sinh</th>
                  <th>Chuẩn đoán</th>
                  <th>Tình trạng</th>
                  <th>Chế độ DD</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? 
                  data.map((value, index) => (
                    <tr>
                      <td>{convertNumber(index + 1,2)}</td>
                      <td>{value.TenBenhNhan}</td>
                      <td>{value.NamSinh}</td>
                      <td>{value.ChanDoanVaoVien}</td>
                      <td>{value.TinhTrangBN}</td>
                      <td>{value.CheDoDD}</td>
                    </tr>
                  ))
                  : (
                  <tr>
                    <td colSpan={6}>Không có bệnh nhân</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ButtonBack slug="/manhinh"/>
    </div>
  );
}
