import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./ScreenCC.scss"
import { url } from "../../api/api";
import { convertNumber } from "../../utils/utils";
import ButtonBack from "../../components/buttonback/ButtonBack";

export default function ScreenCC() {

  const [data,setData] = useState([])
  const [render,setRender] = useState(0)

  useEffect(()=>{
    const fetchData = async () =>{
      const requesURL = `${url}/manhinh/capcuu`
      const response = await fetch(requesURL) 
      const responseJson =await response.json()
      setData(responseJson)
    }
    fetchData()
    Rerender();
  },[render])


  const Rerender = () =>{
    setTimeout(()=>{
      setRender(pre => pre + 1)
    },3000)
  }


  return (
    <div className=" ScreenCC">
      <div className="screen__title">
        <h2>Thông tin bệnh nhân cấp cứu</h2>
      </div>
      <div className="  ">
        <div className="formpay__table" style={{ width: "100%" }}>
          <div className="searchMedia">
            <div className="scroll-table scroll h-500">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Tên bệnh nhân</th>
                    <th>Năm sinh</th>
                    <th>Chuẩn đoán</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 
                    ? data.map((value,index) =>(
                        <tr key={index}>
                          <td>{convertNumber(index,2)}</td>
                          <td>{value.TenBenhNhan}</td>
                          <td>{value.NamSinh}</td>
                          <td>{value.ChanDoan}</td>
                        </tr>
                    ))
                    :<tr>
                      <td colSpan={4}>Không có bệnh nhân</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ButtonBack slug="/manhinh"/>
    </div>
  );
}
