import { React, useEffect } from "react";
import "./medicalRegister.scss";
import { url } from "../../api/api";
import { useState } from "react";
import Swal from "sweetalert2";
import { timeNow } from "../../utils/utils";
import { convertNumber } from "../../utils/utils";
import ButtonBack from "../../components/buttonback/ButtonBack";

export default function MedicalRegister() {
  const [Area, setArea] = useState([]);
  const [active, setActive] = useState(1);
  const [idArea, setIdArea] = useState("khuvuc1");
  const [listRoom, setListRoom] = useState([]);
  const [areaName, setAreaName] = useState("Khu vực 1");
  const [idBenhNhan, setIdBenhNhan] = useState();
  const [patientinFo, setPatientInfo] = useState({});
  const [activeBoxConfirm, setActiveBoxConfirm] = useState(false);
  const [idRoom, setIdRoom] = useState(0);
  const [render,setRender] = useState(0)

  useEffect(() => {
    async function fetchListVideo() {
      const requesURL = `${url}/khuvuckhambenh`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();

      setArea(responseJson);
    }
    fetchListVideo();
  }, []);

  //Danh sách phòng khám
  useEffect(() => {
    async function fetchListVideo() {
      const requesURL = `${url}/phongkham/${idArea}`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();
      setListRoom(responseJson);
    }
    fetchListVideo();
  }, [idArea,render]);

  //Thông tin bênh nhân
  useEffect(() => {
    async function fetchListVideo() {
      const requesURL = `${url}/benhnhan/${idBenhNhan ? idBenhNhan : 1}`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();
      let obj = {};
      if (responseJson.length > 0) {
        obj = {
          ID: responseJson[0].ID,
          Ma:responseJson[0].Ma,
          Ten: responseJson[0].Ten,
          CMND: responseJson[0].CMND,
          DiaChi: responseJson[0].DiaChi,
          NamSinh: responseJson[0].NamSinh,
          SDT: responseJson[0].SoDT,
          NgaySinh: responseJson[0].NgaySinh,
          GioiTinh: responseJson[0].GioiTinh,


        };
      }
      setPatientInfo(obj);
    }
    fetchListVideo();
  }, [idBenhNhan]);

  const handleTabmenu = (id, IdKhuVuc, tenkhucvuc) => {
    setActive(id);
    setIdArea(IdKhuVuc);
    setAreaName(tenkhucvuc);
  };

  const handleChange = (event) => {
    setIdBenhNhan(event.target.value);
  };

  const handleShowboxCofirm = (id) => {
    setIdRoom(id);
    setActiveBoxConfirm(true);
  };

  const handleRegisterOnclick = () => {
    let obj = {};

    if (idBenhNhan === undefined) {
      alert("vui lòng nhập ID bệnh nhân");
      setActiveBoxConfirm(false);
    } else {
      if (Object.keys(patientinFo).length === 0) {
        alert("Mã bệnh nhân không đúng hoặc chưa đăng ký");
        setActiveBoxConfirm(false);
      } else {
        obj = {
          TenPhong: listRoom[idRoom].TenPhong,
          IdKhuVuc: listRoom[idRoom].IdKhuvuc,
          STT: listRoom[idRoom].STT + 1,
          IdBenhNhan: patientinFo.ID,
          MaBenhNhan:patientinFo.Ma,
          TenBenhNhan: patientinFo.Ten,
          CMND: patientinFo.CMND,
          DiaChi: patientinFo.DiaChi,
          NamSinh: patientinFo.NamSinh,
          IdPhongKham: listRoom[idRoom].IdPhongKham,
          TrangThai: "Đang chờ",
          ThoiGian: timeNow(),
          DichVu_ID: listRoom[idRoom].DichVu_ID,
          GiaTien:listRoom[idRoom].GiaTien
        };

        console.log(obj)
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(obj),
        };
        fetch(`${url}/dangkykhambenh/addnew`, requestOptions)
          .then((response) => response.json())
          .then((data) => console.log(data));


        //Tăng số TT phòng khám
        const requestOptions1 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            IdPhongKham: listRoom[idRoom].IdPhongKham,
            STT: listRoom[idRoom].STT + 1,
          }),
        };
        fetch(`${url}/dangkykhambenh/addnewstt`, requestOptions1)
          .then((response) => response.json())
          .then((data) => console.log(data));

        setActiveBoxConfirm(false);
        Swal.fire({
          title: "Lấy số thành công",
          text: `STT: ${convertNumber(obj.STT,4)}`,
          icon: "success",
          confirmButtonText: "Đóng",
        });
        setRender(render + 1)
        setIdRoom()
      }
    }
  };

  return (
    <div className="MedicalRegister">
      <div className="MedicalRegister__top">
        <div className="MedicalRegister__form">
          <div>
            <label>Mã Bệnh nhân:</label>
            <input
              style={{ cursor: "pointer" }}
              type="number"
              placeholder="Mã Bệnh nhân"
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Họ tên:</label>
            <input
              type="text"
              placeholder=""
              disabled="disabled"
              value={patientinFo.Ten ? patientinFo.Ten : ""}
              style={{ textTransform: "uppercase" }}
            />
          </div>
          <div>
            <label>Năm sinh:</label>
            <input
              type="text"
              placeholder=""
              disabled="disabled"
              value={patientinFo.NamSinh ? patientinFo.NamSinh : ""}
            />
          </div>
          <div>
            <label>Số BHTY:</label>
            <input type="text" placeholder="" disabled="disabled" />
          </div>
          <div>
            <label>Từ ngày:</label>
            <input type="text" placeholder="" disabled="disabled" />
          </div>
          <div>
            <label>Đến ngày:</label>
            <input type="text" placeholder="" disabled="disabled" />
          </div>
          <div>
            <label>Đối tượng:</label>
            <input type="text" placeholder="" disabled="disabled" />
          </div>
          <div>
            <label>Giới tính:</label>
            <input
              type="text"
              placeholder=""
              disabled="disabled"
              value={
                Object.keys(patientinFo).length > 0
                  ? patientinFo.GioiTinh
                    ? "Nam"
                    : "Nữ"
                  : ""
              }
            />
          </div>
          <div>
            <label>Ưu tiên:</label>
            <input type="checkbox" placeholder="" id="checkbox-uutien"  style={{flex:"unset"}}/>
          </div>
        </div>
      </div>
      <div className="MedicalRegister__bottom">
        <div className="MedicalRegister__container">
          <div className="MedicalRegister__option">
            {Area.length > 0 ? (
              Area.map((value, index) => (
                <p
                  className={
                    active === value.Id ? "MedicalRegister__option-acitve" : ""
                  }
                  key={value.Id}
                  onClick={() =>
                    handleTabmenu(value.Id, value.IdKhuVuc, value.TenKhuVuc)
                  }
                >
                  {value.TenKhuVuc}
                </p>
              ))
            ) : (
              <h2>Không có dữ liệu</h2>
            )}
          </div>
        </div>

        <ul className="MedicalRegister__layout">
          {listRoom.length > 0
            ? listRoom.map((value, index) => (
                <li
                  className="MedicalRegister_room"
                  key={value.Id}
                  onClick={() => handleShowboxCofirm(index)}
                >
                  <p>
                    {value.TenPhong} - {areaName}
                  </p>
                  <p>
                    {value.MoTa} (STT: {convertNumber(value.STT,4)})
                  </p>
                </li>
              ))
            : "Không có dữ liệu"}
        </ul>
      </div>

      <div
        className={
          activeBoxConfirm
            ? "alert_medicalRegister-active alert_medicalRegister"
            : "alert_medicalRegister"
        }
      >
        <div className="alert_medicalRegister-form">
          <h3>Đăng ký khám bệnh</h3>
          <div>
            <p>
              {listRoom[idRoom] ? listRoom[idRoom].TenPhong : ""} - {areaName}
            </p>
            <p>STT: {listRoom[idRoom] ? convertNumber(listRoom[idRoom].STT + 1,4) : ""} </p>
          </div>
          <div className="MedicalRegister_group-button">
            <span onClick={handleRegisterOnclick}>Lấy số</span>
            <span onClick={() => setActiveBoxConfirm(false)}>Hủy</span>
          </div>
        </div>
      </div>
      <ButtonBack slug="/"/>
    </div>
  );
}
