import React from "react";
import "./pay.scss";
import { useState, useEffect } from "react";
import { url } from "../../api/api";
import ButtonBack from "../../components/buttonback/ButtonBack";
import { convertNumber } from "../../utils/utils";
import Swal from "sweetalert2";

export default function Pay() {
  const [idBenhNhan, setIdBenhNhan] = useState(0);
  const [thongTinBenhNhan, setThongTInBenhNhan] = useState(0);
  const [listData, setListData] = useState([]);

  const handleInput = (event) => {
    setIdBenhNhan(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const requesURL = `${url}/pay/${idBenhNhan}`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();
      setListData(responseJson);
    };
    fetchData();
  }, [idBenhNhan]);

  console.log(listData);

  useEffect(() => {
    async function fetchListVideo() {
      const requesURL = `${url}/benhnhan/${idBenhNhan ? idBenhNhan : 1}`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();
      let obj = {};
      if (responseJson.length > 0) {
        obj = {
          ID: responseJson[0].ID,
          Ten: responseJson[0].Ten,
          CMND: responseJson[0].CMND,
          DiaChi: responseJson[0].DiaChi,
          NamSinh: responseJson[0].NamSinh,
          SDT: responseJson[0].SoDT,
          NgaySinh: responseJson[0].NgaySinh,
          GioiTinh: responseJson[0].GioiTinh,
        };
        setThongTInBenhNhan(obj);
      }
    }
    fetchListVideo();
  }, [idBenhNhan]);

  let total = 0;
  for (let i = 0; i < listData.length; i++) {
    total = total + listData[i].ThanhTien;
  }

  const date = new Date();

  const handlePay = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Chức năng chưa hoàn thiện, Vui lòng thử lại sau",
    });
  };

  return (
    <div className="formpay">
      <div className="formpay__top">
        <div className="formpay__top-group">
          <div className="">
            <p>Mã:</p>
            <input onChange={handleInput} placeholder="Nhập mã bệnh nhân" />
          </div>
          <div className="">
            <p>Họ tên:</p>
            <p>{idBenhNhan ? thongTinBenhNhan.Ten : ""}</p>
          </div>
          <div className="">
            <p>Tuổi:</p>
            <p>
              {idBenhNhan.length !== 0
                ? date.getFullYear() - thongTinBenhNhan.NamSinh
                : ""}
            </p>
          </div>
          <div className="">
            <p>DT:</p>
            <input type="text" placeholder="Dịch vụ theo yêu cầu DT" />
          </div>
          <div className="">
            <p>Địa chỉ:</p>
            <p>{idBenhNhan ? thongTinBenhNhan.DiaChi : ""}</p>
          </div>
          <div className="">
            <p>Thanh toán:</p>
            <p className="total-pay">
              {idBenhNhan ? `${total.toLocaleString()} VND` : 0}
            </p>
          </div>
        </div>
      </div>

      <div className="formpay__table">
        <div className="searchMedia">
          <div className="scroll-table">
            <table>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Nội dung</th>
                  <th>Số lượng</th>
                  <th>Đơn giá</th>
                  <th>Giá trị thanh toán</th>
                  <th>Giá trị đã thanh toán</th>
                  <th>BHTN Ghi nhận</th>
                  <th>Đơn giá BH</th>
                  <th>Thành tiền BHYT</th>
                  <th>BHYT chi trả</th>
                  <th>Ngày khám</th>
                </tr>
              </thead>
              <tbody>
                {listData.length > 0 ? (
                  listData.map((value, index) => (
                    <tr key={index}>
                      <td>{convertNumber(index + 1)}</td>
                      <td>{value.NoiDung}</td>
                      <td>{value.SoLuong}</td>
                      <td>{value.DonGia}</td>
                      <td>{value.GiaTriThanhToan}</td>
                      <td>{value.GiaTriDaThanhToan}</td>
                      <td>{0.0}</td>
                      <td>{value.DonGiaBHYT}</td>
                      <td>{value.ThanhTienBHYT}</td>
                      <td>{value.BHYTChiTra}</td>
                      <td>{value.NgayKham}</td>
                    </tr>
                  ))
                ) : idBenhNhan ? (
                  <tr>
                    <td colSpan={11}>Không có dữ liệu</td>
                  </tr>
                ) : (
                  <tr>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="formpay__bottom">
        <ButtonBack slug="/" />
      
      </div>
    </div>
  );
}
