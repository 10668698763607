import { React, useEffect } from "react";
import "./screen.scss";
import { convertNumber } from "../../utils/utils";
import { useState } from "react";
import { url } from "../../api/api";

export default function Screen__TNDH() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchListVideo() {
      const requesURL = `${url}/manhinh/tiepnhan/khamuutien`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();
      setData(responseJson);
    }
    fetchListVideo();
  }, []);
  console.log(data.length)
  
  return (
    <div className="screen">
      <div className="screen__title">
        <h2>Danh sách tiếp nhận - Đặt hẹn</h2>
        <p>(Bệnh nhân vui lòng chờ gọi số)</p>
      </div>
      <div className="formpay__table">
        <div className="searchMedia">
          <div className="scroll-table">
            <table>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Quầy tiếp nhận</th>
                  <th>Ghi Chú</th>
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((value, index) => (
                    <tr key={index}>
                      <td>{convertNumber(value.STT, 2)}</td>
                      <td>{value.TrangThai}</td>
                      <td>-</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>Không có bệnh nhân</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
