import {useState,useEffect} from "react";
import { url } from "../../api/api";
import ButtonBack from "../../components/buttonback/ButtonBack";
import { convertNumber } from "../../utils/utils";
import "./searchservice.scss";

export default function SearchService() {

  const [idDichvu,setIdDichVu] = useState ('')
  const [listSearch,setListSearch] = useState([]);

  const handleChange = (event) => {
    setIdDichVu(event.target.value);
  }


  useEffect(() => {
    async function fetchListVideo() {
      const requesURL = `${url}/service/${idDichvu}`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();

      setListSearch(responseJson)
    }
    fetchListVideo();
  }, [idDichvu]);



  return (
    <div className="searchMedia">
      <h1 className="">Tra cứu thông tin dịch vụ</h1>
      <div className="searchMedia-input" style={{ display: "flex" }}>
        <p>Tìm kiếm</p>
        <input placeholder="Nhập tên dịch vụ | mã dịch vụ" onChange={handleChange}/>
      </div>
      <div className="scroll-table">
      <table>
        <thead>
          <tr>
            <th>STT</th>
            <th>Tên</th>
            <th>Mã</th>
            <th>Giá</th>
            <th>Giá BHYT</th>

          </tr>
        </thead>

        <tbody>
          {
          listSearch.length ?
          listSearch.map((value, index) => (
              <tr key={index}>
                <td>{convertNumber(index+1,2)}</td>
                <td>{value.Ten}</td>
                <td>{value.Ma ? value.Ma : "-"}</td>
                <td>{value.DonGia.toLocaleString()}</td>
                <td>{value.DonGiaBHYT.toLocaleString()}</td>
              </tr>
            )) 
            : <tr><td colSpan={5}>Không có dữ liệu</td></tr>
          }
        </tbody>
      </table>
      </div>
      <ButtonBack slug="/"></ButtonBack>
    </div>
  );
}
