import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

export default function Today() {

  const [time,setTime] = useState('');
  const [date,setDate] = useState('');

  useEffect(() => {
    let getTimeInterVal = setInterval(() => {
        const date = new Date();
        setTime(date.toLocaleTimeString());
    }, 1000);
    setInterval(() => {
        const date = new Date();
        setDate(date.toLocaleDateString());
    }, 1000);
    return(()=>{
        clearInterval(getTimeInterVal)
    });
  }, []);
  return (
    <div>
        <div className='date'>{time} ,{date}</div>
    </div>
    
  )
}
