import { useState } from "react";
import { React, useEffect } from "react";
import { convertDateTime, convertNumber } from "../../utils/utils";
import { url } from "../../api/api.js";
import ButtonBack from "../../components/buttonback/ButtonBack";

export default function SearchBill() {
  const [idBenhNhan, setIdBenhNhan] = useState("");
  const [listBill, setListBill] = useState([]);

  const handleChange = (event) => {
    setIdBenhNhan(event.target.value);
  };

  useEffect(() => {
    async function fetchListVideo() {
      const requesURL = `${url}/bill/${idBenhNhan}`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();

      setListBill(responseJson);
    }
    fetchListVideo();
  }, [idBenhNhan]);

  console.log(listBill);

  return (
    <div className="searchMedia">
      <h1 className="">Tra cứu hóa đơn</h1>
      <div className="searchMedia-input" style={{ display: "flex" }}>
        <p>Tìm kiếm</p>
        <input
          placeholder="Nhập tên bệnh nhân | mã bệnh nhân"
          onChange={handleChange}
        />
      </div>
      <div className="scroll-table">
        <table>
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên</th>
              <th>Năm sinh</th>
              <th>Số hóa đơn</th>
              <th>Ngày lập</th>
              <th>Địa chỉ</th>
              <th>Ghi chú</th>
              <th>Tổng thanh toán</th>
            </tr>
          </thead>
          <tbody>
            {listBill.length > 0 ? (
              listBill.map((value, index) => (
                <tr key={index}>
                  <td>{convertNumber(index + 1, 2)}</td>
                  <td>{value.Ten ? value.Ten : "-"} </td>
                  <td>{value.NamSinh ? value.NamSinh : "-"}</td>
                  <td>{value.SoHoaDon ? value.SoHoaDon : "-"}</td>
                  <td>
                    {value.NgayLap ? convertDateTime(value.NgayLap) : "-"}
                  </td>
                  <td>{value.DiaChi ? value.DiaChi : "-"}</td>
                  <td>{value.GhiChu ? value.GhiChu : "-"}</td>
                  <td>
                    {value.TongGTThanhToan
                      ? `${value.TongGTThanhToan.toLocaleString()}VND`
                      : "0"}
                  </td>
                </tr>
              ))
            ) : idBenhNhan ? (
              <tr>
                <td colSpan={8}>Không có dữ liệu</td>
              </tr>
            ) : (
              <tr><td></td></tr>
            )}
          </tbody>
        </table>
      </div>
      <ButtonBack slug="/"/>
    </div>
  );
}
