import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { url } from "../../api/api";
import ButtonBack from "../../components/buttonback/ButtonBack";
import {convertNumber} from "../../utils/utils.js"

function ScreenPhongSanh() {
  const [data,setData] = useState([])
  const [render,setRender] = useState(0)

  useEffect(()=>{
    async function fetchListData() {
      const requesURL = `${url}/manhinh/phongsanh`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();
      setData(responseJson);
    }
    fetchListData();
    reRender();
  },[render])

  function reRender() {
    setTimeout(() => {
      setRender((pre) => pre + 1);
    }, 3000);
  }

  return (
    <div className="">
      <div className="screen__title">
        <h2>Thông tin dịch vụ phòng sanh</h2>
      </div>
      <div className="formpay__table">
        <div className="searchMedia">
          <div className="scroll-table">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên bệnh nhân</th>
                  <th>Năm sinh</th>
                  <th>Trai/Gái</th>
                  <th>Số lượng</th>
                  <th>Tình trạng</th>
                  <th>Cân nặng (Kg)</th>
                </tr>
              </thead>
              <tbody>
              {data.length > 0 ? (
                  data.map((value, index) => (
                    <tr key={index}>
                      <td>{convertNumber(index + 1, 2)}</td>
                      <td>{value.TenBenhNhan}</td>
                      <td>{value.NamSinh}</td>
                      <td>{value.GTBe}</td>
                      <td>{value.SL}</td>
                      <td>{value.TinhTrang}</td>
                      <td>{value.CanNang.toFixed(1)}Kg</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td>Chưa có bệnh nhân nào</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ButtonBack slug="/manhinh"/>
    </div>
  );
}

export default ScreenPhongSanh;
