import React from 'react'
import "./serviceLayout.scss"
import { useNavigate } from 'react-router-dom'

export default function ServiceLayout({id,name,slug,idService}) {

  const navigate = useNavigate();
  const handleServiceItemOnclick = (slug)=>{
    localStorage.setItem("ID__SERVICE", JSON.stringify(id))
    navigate(`/hienthi/${slug}`)
  }

  return (
    <div className="ServiceLayout-item" key={id} onClick={() =>handleServiceItemOnclick(slug)}>{name}</div>
  )
}
