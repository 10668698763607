export const service = [
    {
        id:0,
        name:"Khám dịch vụ",
        count:0,
        idService:'khamdichvu',
        slug:"kham-dich-vu"
    },
    {
        id:1,
        name:"Khám ưu tiên",
        count:0,
        idService:'khamuutien',
        slug:"kham-uu-tien"
    },
    {
        id:2,
        name:"Khám đặt hẹn",
        count:0,
        idService:'khamdathen',
        slug:"kham-dat-hen"
    },
]


//status [chờ tiếp nhận , tiếp nhận,tiếp nhận xong]
export const listGetnumber = [
    
]

export const medicalExaminationArea = [
    {
        id:0,
        idArea:"khuvuc0",
        name:"Khu vuc 0"
    },
    {
        id:1,
        idArea:"khuvuc1",
        name:"Khu vuc 1"
    },
    {
        id:2,
        idArea:"khuvuc2",
        name:"Khu vuc 2"
    },
    {
        id:3,
        idArea:"khuvuc3",
        name:"Khu vuc 3"
    }
]

export const MedicalClinic = [
    {
        idArea:"khuvuc0",
        room:"Phòng 1",
        stt:0

    },
    {
        idArea:"khuvuc0",
        room:"Phòng 2",
        stt:2

    },
    {
        idArea:"khuvuc0",
        room:"Phòng 3",
        stt:0

    },
    {
        idArea:"khuvuc0",
        room:"Phòng 4",
        stt:0

    },
    {
        idArea:"khuvuc1",
        room:"Phòng 5",
        stt:0

    },
    {
        idArea:"khuvuc1",
        room:"Phòng 6",
        stt:0

    },
    {
        idArea:"khuvuc2",
        room:"Phòng 7",
        stt:0

    },
    {
        idArea:"khuvuc3",
        room:"Phòng 8",
        stt:0

    },
    {
        idArea:"khuvuc3",
        room:"Phòng 9",
        stt:0

    },
    {
        idArea:"khuvuc3",
        room:"Phòng 10",
        stt:0

    },
    {
        idArea:"khuvuc3",
        room:"Phòng 11",
        stt:0

    },

]

export const listGetnumbers =[] 