export const  convertNumber = (number,many) =>{
    return (number).toLocaleString('en-US', {minimumIntegerDigits: many, useGrouping:false})
  }


export const convertDateTime = (time) =>{
  const date = new Date(time);
  return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + 
        ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + 
        date.getFullYear();
}

export const  timeNow = (number,many) =>{
  // const date = new Date();

  // const Day  = date.toLocaleDateString()
  // const Time = date.toLocaleTimeString()
  return new Date().toISOString().slice(0, 19).replace('T', ' ');
}

