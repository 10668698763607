import React from 'react'
import { useState } from 'react'
import './login.scss'
import { url } from '../../api/api'

 


function Login({token}) {
  const [user,setUser] = useState('')
  const [password,setPassword] = useState('')
  
  const handleBtnLogin = () =>{
    if(user.length === 0 ){
      alert("Vui lòng nhập tài khoản")
    }
    else if(password.length ===0){
      alert("Vui lòng nhập mật khẩu")
    }
    else{
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user:user,
          password:password,
        }),
      };
      fetch(`${url}/dangnhap`, requestOptions)
        .then((response) => response.json())
        .then((data) => 
        {
          token(data.token)
          if(data.msg === false){
            alert("Tài khoản hoặc mật khẩu không đúng")
          }
        }
        );
    }
    console.log(true)

  }

  return (
    <div className='login-container'>
      <div className="loginForm">
          <div className='loginForm-title'>
            <h3>Đăng nhập</h3>
          </div>
          <div className="loginForm-groupby">
            <p>Tên đăng nhập</p>
            <input type="text" placeholder='Tên đăng nhập' onChange={(e) =>setUser(e.target.value)}/>
          </div>
          <div className="loginForm-groupby">
            <p>Mật khẩu</p>
            <input type="password" placeholder='Mật khấu' onChange={(e) =>setPassword(e.target.value)}/>
          </div>
          <div className="btn-login"  onClick={handleBtnLogin}>Đăng nhập</div>
      </div>
  </div>
  )
}

export default Login