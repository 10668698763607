import React from 'react'
import ButtonBack from '../../components/buttonback/ButtonBack'
import "./map.scss"

function Map() {
  return (
    <div className='map-container'>
        <h2>Sơ đồ bệnh viện</h2>
        <img src="https://www.bvbnd.vn/wp-content/uploads/2020/10/So-do-BVBND-2016.jpg" alt="" />

        <ButtonBack slug="/"/>
    </div>  
  )
}
 
export default Map