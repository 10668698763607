import React from "react";

function ScreenBS() {
  return (
    <div className="screen">
      <div className="screen__title">
        <h2>Danh sách bác sĩ tầng 2</h2>
      </div>
      <div className="screen__table">
        <table border={1}>
          <thead>
            <tr>
              <th>Phòng</th>
              <th style={{width:"40%"}}>Bác sĩ</th>
              <th>CK</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>02-T02</td>
              <td></td>
              <td>Ngoại</td>
            </tr>
            <tr>
              <td>05-T02</td>
              <td></td>
              <td>Ngoại</td>
            </tr>
            <tr>
              <td>06-T02</td>
              <td></td>
              <td>Ngoại</td>
            </tr>
            <tr>
              <td>07-T02</td>
              <td></td>
              <td>Ngoại</td>
            </tr>
            
            
          </tbody>
        </table>

        <table border={1}>
          <thead>
            <tr>
              <th>Phòng</th>
              <th style={{width:"40%"}}>Bác sĩ</th>
              <th>CK</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ScreenBS;
