import {React,} from 'react'
import "./receivePage.scss"
import {service} from "../../fakeDB"
import ServiceLayout from '../../components/service/ServiceLayout'
import {useNavigate} from "react-router-dom"

function ReceivePage() {
  const navigate = useNavigate();
  
  return (
    <div className='home'>
       <div className='ServiceLayout'>
         {service.map((value) =>(
            <ServiceLayout
                idService = {value.idService}
                name = {value.name}
                key = {value.id}
                id = {value.id}
                slug = {value.slug}
            />
          ))}
        <div className="ServiceLayout-item btn-getnumber" onClick={()=>navigate(`/layso-tiepnhan`)}>Lấy số</div>
       </div>

    </div>
  )
}

export default ReceivePage