import React from "react";
import { useState, useEffect } from "react";
import "./getnumber.scss";

import img2 from "../../images/OrWKYWw4.jpg";
import Swal from "sweetalert2";
import { convertNumber, timeNow } from "../../utils/utils";
import { url } from "../../api/api";
import ButtonBack from "../../components/buttonback/ButtonBack";

export default function GetnumberReceive() {
  const [id, setId] = useState();
  const [service, setService] = useState([]);
  const [objNumber, setObjNumber] = useState({});
  const [render,setRender] = useState(0)
  const [active,setActive] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const requesURL = `${url}/dichvutiepnhan`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();
      setService(responseJson);
    };
    fetchData();
  }, [render]);

  const handleActiveService = (ID, index) => {
    setId(index);
    setActive(ID)
    let obj = {
      STT: service[index].STT +1,
      TenDichVu_TN: service[index].TenDichVu_TN,
      IdDichVu_TN: service[index].IdDichVu_TN,
      slug: service[index].slug,
      TrangThai: "Đang chờ",
      ThoiGian: timeNow(),
    };
    setObjNumber(obj);
  };
  const getNumberOnclick = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    if(id !== undefined ){
      fetch(`${url}/dichvutiepnhan`, requestOptions)
      .then((response) => response.json())
      .then((responseJson) => console.log(responseJson));

      fetch(`${url}/dichvutiepnhanstt`, requestOptions)
      .then((response) => response.json())
      .then((responseJson) => console.log(responseJson));
      Swal.fire({
        title: "Lấy số thành công",
        text: `Khám ${data.TenDichVu_TN} : ${data.STT}`,
        icon: "success",
        confirmButtonText: "Đóng",
      });
      setRender(render +1)
      setId()
      setActive()
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vui lòng chọn dịch vụ tiếp nhận',
      })
    }
  };
  

  return (
    <div className="getNumber">
      <h5 className="getNumber__title">KHU VỰC LẤY SỐ TIẾP NHẬN</h5>
      <div className="getNumber__container">
        <div className="getNumber__container__img">
          <img src={img2} alt="ảnh 1" />
          {/* <img className='getNumber__logo__img' src={ig1} alt="ảnh 2"/> */}
        </div>
        <div className="getNumber__content">
          <ul className="">
            {service.map((value, index) => (
              <li
                className={active === value.ID ? "getNumber__item-active" : ""}
                onClick={() => handleActiveService(value.ID, index)}
                key={value.ID}
              >
                {value.TenDichVu_TN}
              </li>
            ))}
          </ul>
          <div className="submit__number">
            <p>{typeof(id) ==='number' ? convertNumber(service[id].STT,4) : 0}</p>
            <div
              className="btn__getnumber"
              onClick={() => getNumberOnclick(objNumber)}
            >
              Lấy số
            </div>
          </div>
        </div>
      </div>
      <ButtonBack slug="/"/>
    </div>
  );
}
