import React from 'react'
import "./homepage.scss"

import {ImListNumbered} from "react-icons/im";
import {MdScreenSearchDesktop,MdOutlineScreenSearchDesktop} from  "react-icons/md"; 
import {FaHospitalUser} from  "react-icons/fa"; 
import { TfiMapAlt } from "react-icons/tfi";
import {IoReceiptOutline} from "react-icons/io5"
import {BsReceiptCutoff,BsEmojiHeartEyes} from "react-icons/bs"


import {useNavigate} from "react-router-dom"

function HomePage() {


    // (async () => {
    //     const rawResponse = await fetch("http://localhost:5000/products", {
    //       method: "POST",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ 
    //         TenDichVu_TN: 'kham tongquat',
    //         IdDichVu_TN:'khamtongquat',
    //         slug:'kham-dich-vu'
    //       }),
    //     });
    //     const content = await rawResponse.json();
  
  
    //     if(content.status === 400){
    //       console.log('false')
  
    //     }
    //     else{
    //       console.log('true')
    //     }
    //   })();

      const navigate = useNavigate()

  return (
    <div className='HomePage'>
        <div className='header__title-flow'>
            <p>Chào mừng quý khách đã đến xử dụng KIOSK thông minh tại Bệnh Viện Đa Khoa Đồng Nai - CS2</p>
        </div>
        <div className="HomePage__list">
            <div className='HomePage__item' onClick={() =>navigate('/layso-tiepnhan')}>
                <ImListNumbered/>
                 <p>Lấy số thứ tự tiếp đón</p>
            </div>
            <div className='HomePage__item' onClick={() =>navigate("/dangkykhambenh")}>
                <FaHospitalUser/>
                 <p>Đăng ký khám bệnh</p>
            </div>
            <div className='HomePage__item' onClick={() =>navigate("/tracuubenhan")}>
                <MdScreenSearchDesktop/>
                 <p>Tra cứu thông tin bệnh án</p>
            </div>
            <div className='HomePage__item' onClick={() =>navigate("/bando")}>
                <TfiMapAlt/>
                 <p>Sơ đồ bệnh viện</p>
            </div>
            <div className='HomePage__item' onClick={() =>navigate("/thanhtoan")}>
                <BsReceiptCutoff/>
                 <p>Thanh toán viện phí</p>
            </div>
            <div className='HomePage__item' onClick={() =>navigate("/tracuudichvu")}>
                <MdOutlineScreenSearchDesktop/>
                 <p>Tra cứu dịch vụ</p>
            </div>
            <div className='HomePage__item' onClick={() =>navigate("/tracuuhoadon")}>
                 <IoReceiptOutline/>
                 <p>Tra cứu hóa đơn</p>
            </div>
            <div className='HomePage__item' onClick={() =>navigate("/danhgia")}>
                <BsEmojiHeartEyes/>
                 <p>Đánh giá dịch vụ</p>
            </div>
        </div>
    </div>
  )
}

export default HomePage